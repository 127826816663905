import {Component, useEffect, useState} from "react";

import {
    AutoComplete,
    Button,
    Card,
    ConfigProvider,
    Flex,
    Form,
    Input,
    Layout,
    message,
    Row,
    Select, Space, Spin,
    Table,
    Upload
} from "antd";
import Apiurl from "../ConstURL";
import axios, {options} from "axios";
import {
    FileExcelOutlined, InboxOutlined,
} from '@ant-design/icons';




export default function Addtask() {
    const [form] = Form.useForm()

    const [currentValue, setCurrentValue] = useState('')
    const [AllTypes, setAllTypes] = useState([]);
    const [AllTypesMas, setAllTypesMas] = useState([]);

    const [Loading, setLoading] = useState(false);
    const [AllCount, setAllCount] = useState(0);




    useEffect(()=>{
        axios.get(Apiurl+"/all/types?token="+localStorage.getItem("token")).then((res: any) => {


            var mas = []
            setAllTypesMas(res.data.Data)
            res.data.Data.forEach(item => {
                mas.push({label: item, value: item})
            })
            setAllTypes(mas)

        }).catch((err: Error) => {
            console.log(err)
        })


    },[]);



    return (<Flex vertical={false} justify={"center"} style={{paddingTop: "7px"}} >

            <Space type="flex" size='large' justify="center" align="center" direction="vertical"
            style={{padding: "20px"}}>

                <Card style={{

                }}>
                    <Spin spinning={Loading} size={"large"}>
                    <Flex vertical={false} justify={"center"} style={{paddingTop: "0px"}} >

                        <div style={{fontSize:"1.5em"}} color={"white"}>Всего товара: {AllCount}</div>
                    </Flex>
                    </Spin>

                </Card>
                <AutoComplete
                    popupMatchSelectWidth={1200}
                style={{
                    width: 1200,
                }}
                options={AllTypes}
                onSelect={(value)=> {
                    console.log(value)
                    setCurrentValue(value)
                }}

                onChange={(value)=>{
                    const mas = [];
                    AllTypesMas.forEach(item => {
                        if (item.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                            mas.push({label: item, value: item})
                        }

                    })
                    setAllTypes(mas)
                }
                }
                // onSearch={(value)=>{
                //
                // }
                // }
                size="large"
            >
                <Input.Search
                    onSearch={()=>{setLoading(true)
                        setAllCount(0)
                        axios.get(Apiurl+"/all/items?"+ new URLSearchParams({
                            token: localStorage.getItem("token"),
                            filter_category: currentValue

                        })).then((res: any) => {
                            if (res.status === 200) {

                                setAllCount(res.data.Allcount)
                                setLoading(false)
                            }else {
                                message.error("Ошибка в отправке запроса!")
                            }

                        }).catch((err: Error) => {
                            console.log(err)
                        })}}
                    placeholder="Выберите категорию" enterButton />
            </AutoComplete>

                {AllCount!==0 && <Button type={"primary"} icon={<FileExcelOutlined/>}
                                         rel="noreferrer" htmlType={"a"} target="_blank"
                                         href={Apiurl+"/all/items/excel?"+ new URLSearchParams({
                                             token: localStorage.getItem("token"),
                                             filter_category: currentValue

                                         })}>Выгрузить Excel</Button>
                }
        </Space>
        </Flex>

    );

}