import logo from './logo.svg';
import './App.css';
import {ConfigProvider, Layout, theme} from "antd";
import {AuthProvider} from "./funcs/Authcontext";
import {Header} from "antd/es/layout/layout";
import {Route, Routes, useParams} from "react-router-dom";

import PrivateRoutes1 from "./funcs/CheckAuth";
import Authpage from "./pages/Authpage";
import Mainpage from "./pages/Mainpage";

import ruRu from 'antd/locale/ru_RU';

function App() {
  return (
      <ConfigProvider
          locale={ruRu}
          theme={{

            algorithm: theme.darkAlgorithm,
            "token": {

              "colorPrimary": "#a017ff",
              "colorInfo": "#a017ff",
              "fontSize": 16
            },

          }}
      >
        <Layout style={{minHeight: "100vh"}}>
          <AuthProvider>
            <Routes>
              <Route path="/login" element={<Authpage/>}/>

              <Route element={<PrivateRoutes1/>}>
                <Route exact path='/*' element={<Mainpage/>}/>


              </Route>
            </Routes>

          </AuthProvider>
        </Layout>
      </ConfigProvider>
  );
}

export default App;
